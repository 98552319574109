@import url("https://fonts.googleapis.com/css2?family=Overpass:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
/* ======== Breakpoints ======== */
$mobilePortrait: 478px;
$mobile: 767px;
$tablet: 1024px;
$laptop: 1080px;

$desktopLarge: 1441px;
$desktopExtraLarge: 1920px;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.H0 {
  /* Headings/H0 */
  font-family: Overpass;
  font-size: 90px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 108px */
  letter-spacing: -4.5px;
}
h1 {
  font-family: "Overpass", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 60px;
  line-height: 120%;
  letter-spacing: -0.05em;
}
h2 {
  font-family: "Overpass", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 120%;
  letter-spacing: -0.05em;
  display: inline-block;
}

h3 {
  font-family: "Overpass", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 130%;
  letter-spacing: -0.03em;
  display: inline-block;
}

h4 {
  font-family: "Overpass", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -0.03em;
  display: inline-block;
}
p {
  font-family: "Libre Franklin", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.01em;
  display: inline-block;
}

.small-heading {
  font-style: normal;
  margin: 0 0 20px 0;
  font-weight: bold;
  font-size: 11px;
  line-height: 170%;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  color: #637981;
}

.primary-btn {
  padding: 15px 30px;
  background: #00353e;
  border-radius: 3px;
  font-family: "Overpass", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 170%;
  letter-spacing: -0.01em;
  color: #ffffff;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
}

.secondary-btn {
  padding: 15px 30px;
  background: #00c5cb;
  border-radius: 3px;
  font-family: "Overpass", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 170%;
  letter-spacing: -0.01em;
  color: #00353e;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  transition: 0.25s;
}

.secondary-btn:hover {
  transform: scale(1.05, 1.05);
}

.primary-btn-arrow {
  padding: 15px 38px 15px 30px;
  background: #00353e;
  border-radius: 3px;
  font-family: "Overpass", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 170%;
  letter-spacing: -0.01em;
  color: #ffffff;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  background-image: url("./assets/icons/icon-arrow-button.svg");
  background-repeat: no-repeat;
  background-position: 96% center;
  border: none;
  outline: none;
  transition: 0.25s;
}

.primary-btn-arrow:hover {
  transform: scale(1.05, 1.05);
  background-position: 99% center;
}

.slick-prev:before,
.slick-next:before {
  color: black !important;
}
.primary-btn-arrow:disabled {
  opacity: 0.2;
  cursor: not-allowed;
}
.bg {
  background-color: #fafafb;
}

.carousel-dots {
  bottom: -20px !important;
  .carousel-dot {
    border: 2px solid #000 !important;
  }

  .carousel-dot-active {
    background-color: #000 !important;
  }
}

.dsar {
  background-color: transparent !important;
}
.dsar .btn-primary {
  padding: 15px 30px !important;
  background: #00353e !important;
  border-radius: 3px !important;
  font-family: "Overpass", sans-serif !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 12px !important;
  line-height: 170% !important;
  letter-spacing: -0.01em !important;
  color: #ffffff !important;
  display: inline-block !important;
  text-decoration: none !important;
  border: none !important;
  cursor: pointer !important;
}

.dsar .btn-primary:hover {
  transform: scale(1.05, 1.05);
}

.dsar h1 {
  font-family: "Overpass", sans-serif !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 40px !important;
  line-height: 120% !important;
  letter-spacing: -0.05em !important;
  display: inline-block !important;
}
.dsar .btn-info {
  padding: 15px 30px !important;
  background: #00c5cb !important;
  border-radius: 3px !important;
  font-family: "Overpass", sans-serif !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 12px !important;
  line-height: 170% !important;
  letter-spacing: -0.01em !important;
  color: #00353e !important;
  display: inline-block !important;
  text-decoration: none !important;
  cursor: pointer !important;
  outline: none !important;
  transition: 0.25s !important;
  border: none !important;
  margin-left: 30px;
}
.dsar {
  display: none;
}

.container #my-form-element .dsar {
  display: inline-block !important;
}
.dsar .input-sm,
.dsar select.input-sm {
  box-shadow: 0px 0px 0px #fff inset !important;
  box-sizing: border-box !important;
  background: #ffffff !important;
  border: 1.5px solid #ecedee !important;
  border-radius: 3px !important;
  margin-bottom: 10px !important;
  padding: 12px 24px !important;
  font-family: "Overpass", sans-serif !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 14px !important;
  line-height: 150% !important;
  letter-spacing: -0.01em !important;
  color: #002532e6 !important;
  height: unset !important;
}

.dsar .btn-info:hover {
  transform: scale(1.05, 1.05);
}

agm-map {
  height: 300px;
}

.articleParagraphs p p {
  margin-bottom: 15px;
  width: 100%;
}

.clearLink {
  font-family: "Overpass", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 1;
  letter-spacing: -0.01em;
  color: #00c5cb;
  display: flex;
  text-decoration: none;
  cursor: pointer;
  transition: 0.25s all;
  img {
    margin-left: 5px;
    transition: 0.25s all;
    margin-bottom: 0px;
  }
}

.clearLink:hover img {
  margin-left: 15px;
}

.video iframe {
  width: 100%;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
}

.ngx-pagination {
  .current {
    background: rgba(0, 37, 50, 0.9) !important;
    color: #ffffff !important;
  }
  a:hover,
  .ngx-pagination button:hover {
    background: transparent !important;
  }
  .ng-star-inserted {
    font-family: "Libre Franklin", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.01em;
    display: inline-block;
  }
}

.ngx-slider-span {
  margin: 0 0 10px 0;
}

.ngx-slider .ngx-slider-bar {
  background: #135559 !important;
  height: 6px !important;
}

.ngx-slider .ngx-slider-pointer {
  width: 30px !important;
  height: 30px !important;
  background-color: #135559 !important;
}

.ngx-slider .ngx-slider-pointer:after {
  display: none !important;
}

@media only screen and (max-width: 1024px) {
  h1 {
    font-size: 42px;
  }
}

@media only screen and (max-width: 500px) {
  h1 {
    font-size: 28px;
  }
}

.jw-modal {
  .jw-modal-body {
    @media screen and (max-width: $mobile) {
      height: 50dvh;
      margin: 50% 40px !important;
    }

    iframe {
      @media screen and (max-width: $mobile) {
        height: 45dvh;
      }
    }
  }
}

.slick-arrow {
  position: absolute;
  top: -50px;
  right: 0px;
  cursor: pointer;

  @media screen and (max-width: $tablet) {
    top: auto;
    right: auto;
    bottom: -50px;
    left: 50%;
  }

  @media screen and (max-width: $mobile) {
    top: auto;
    right: auto;
    bottom: -50px;
    left: 50%;
  }

  circle,
  path {
    transition: all 0.3s;
  }

  &:hover {
    circle,
    path {
      stroke: #00c5cb;
    }
  }

  &.next {
    right: 368px;

    @media screen and (max-width: $tablet) {
      right: auto;
      margin-left: 12px;
    }

    @media screen and (max-width: $mobile) {
      right: auto;
      margin-left: 12px;
    }
  }

  &.previous {
    right: 410px;

    @media screen and (max-width: $tablet) {
      right: auto;
      margin-left: -36px;
    }

    @media screen and (max-width: $mobile) {
      right: auto;
      margin-left: -36px;
    }
  }
}

.featured-properties-carousel {
  .slick-arrow {
    @media screen and (max-width: $tablet) {
      bottom: -70px;
    }

    @media screen and (max-width: $mobile) {
      bottom: -70px;
    }
  }
}

.popular-links-carousel {
  .slick-track {
    display: flex !important;
    align-items: stretch !important;
    padding-left: 190px !important;
    padding-top: 40px;
    padding-bottom: 40px;

    @media only screen and (max-width: 1660px) {
      padding-left: 20px !important;
    }

    // @media screen and (max-width: $desktopLarge) {
    //     padding-left: 180px !important;
    //  }

    @media screen and (max-width: $tablet) {
      padding-left: 0px !important;
    }

    @media screen and (max-width: $mobile) {
      padding-left: 0px !important;
    }
  }
  .slick-slide {
    height: auto !important;
    width: 360px !important;
  }

  .slick-arrow {
    position: absolute;
    bottom: -10px;
    right: 0px;
    cursor: pointer;

    &.next {
      right: auto;
      margin-left: 12px;
    }

    &.previous {
      right: auto;
      margin-left: -36px;
    }
  }
}

.foursight-carousel {
  position: relative;

  .slick-arrow {
    top: unset;
    bottom: -27px;

    @media screen and (max-width: $tablet) {
      display: none !important;
    }

    @media screen and (max-width: $mobile) {
      display: none !important;
    }
  }

  .slick-arrow.previous {
    left: 280px;
    // margin-left: -48px;
    right: unset;
  }

  .slick-arrow.next {
    left: 325px;
    right: unset;
  }

  .slick-dots {
    position: absolute;
    // bottom: 22px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    // left: -35px;
    width: auto;

    @media screen and (max-width: $tablet) {
      left: 50%;
      margin-left: -100px;
      bottom: 0px;
    }

    @media screen and (max-width: $mobile) {
      left: 50%;
      margin-left: -100px;
      bottom: 0px;
    }

    button {
      &:before {
        color: #0a2431 !important;
        opacity: 1;
      }
    }

    .slick-active {
      button {
        &:before {
          color: #00c5cb !important;
          opacity: 1;
        }
      }
    }
  }
}

.team-carousel {
  margin-left: 0px;
  margin-right: 0px;
  .slick-track {
    padding-bottom: 20px !important;
  }

  .slick-arrow {
    display: none !important;
    top: unset;
    bottom: -20px;
  }

  .slick-dots {
    button {
      &:before {
        color: #0a2431 !important;
        opacity: 1;
      }
    }

    .slick-active {
      button {
        &:before {
          color: #00c5cb !important;
          opacity: 1;
        }
      }
    }
  }
}

.our-approach-carousel,
.our-commitment-carousel {
  margin-left: 0px;
  margin-right: 0px;

  @media screen and (max-width: $tablet) {
    width: 100% !important;
  }

  @media screen and (max-width: $mobile) {
    width: 100% !important;
  }

  .slick-track {
    padding-bottom: 40px !important;
  }

  .slick-arrow {
    top: unset;
    bottom: -20px;
    z-index: 99;

    @media screen and (max-width: $tablet) {
      display: none !important;
    }

    @media screen and (max-width: $mobile) {
      display: none !important;
    }

    &.previous {
      left: 110px;
    }

    &.next {
      left: 153px;
    }
  }

  &.slider2 {
    .slick-arrow.previous {
      left: 135px;
    }

    .slick-arrow.next {
      left: 178px;
    }
  }

  &.slider3 {
    .slick-arrow.previous {
      left: 225px;
    }

    .slick-arrow.next {
      left: 268px;
    }
  }

  .slick-dots {
    position: absolute;
    bottom: -20px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: auto;
    left: 0px;

    @media screen and (max-width: $tablet) {
      left: 50% !important;
      margin-left: -100px !important;
    }

    @media screen and (max-width: $mobile) {
      left: 50% !important;
      margin-left: -100px !important;
    }

    button {
      &:before {
        color: #0a2431 !important;
        opacity: 1;
      }
    }

    .slick-active {
      button {
        &:before {
          color: #00c5cb !important;
          opacity: 1;
        }
      }
    }
  }
}

.social-our-approach-carousel {
  margin-left: 0px;
  margin-right: 0px;

  @media screen and (max-width: $tablet) {
    width: 100% !important;
  }

  @media screen and (max-width: $mobile) {
    width: 100% !important;
  }

  .slick-track {
    padding-bottom: 40px !important;
  }

  .slick-arrow {
    top: unset;
    bottom: -22px;

    @media screen and (max-width: $tablet) {
      display: none !important;
    }

    @media screen and (max-width: $mobile) {
      display: none !important;
    }
  }

  .slick-dots {
    position: absolute;
    bottom: -20px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 300px;
    left: -58px;

    @media screen and (max-width: $tablet) {
      left: 50% !important;
      margin-left: -150px !important;
    }

    @media screen and (max-width: $mobile) {
      left: 50% !important;
      margin-left: -150px !important;
    }

    button {
      &:before {
        color: #0a2431 !important;
        opacity: 1;
      }
    }

    .slick-active {
      button {
        &:before {
          color: #00c5cb !important;
          opacity: 1;
        }
      }
    }
  }

  .slick-arrow {
    &.previous {
      left: 210px;
      z-index: 999 !important;
    }

    &.next {
      left: 253px;
    }
  }
}

.sus-social-partners-carousel {
  @media screen and (max-width: $tablet) {
    width: 100% !important;
  }

  @media screen and (max-width: $mobile) {
    width: 100% !important;
  }

  .slick-track {
    padding-bottom: 40px !important;
  }

  .slick-arrow {
    top: unset;
    bottom: -18px;

    @media screen and (max-width: $tablet) {
      display: none !important;
    }

    @media screen and (max-width: $mobile) {
      display: none !important;
    }
  }

  .slick-dots {
    position: absolute;
    bottom: -20px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 300px;
    left: -68px;

    @media screen and (max-width: $tablet) {
      left: 50% !important;
      margin-left: -150px !important;
    }

    @media screen and (max-width: $mobile) {
      left: 50% !important;
      margin-left: -150px !important;
    }

    button {
      &:before {
        color: #0a2431 !important;
        opacity: 1;
      }
    }

    .slick-active {
      button {
        &:before {
          color: #00c5cb !important;
          opacity: 1;
        }
      }
    }
  }

  .slick-arrow {
    &.previous {
      left: 210px;
    }

    &.next {
      left: 253px;
    }
  }
}

.white-carousel-nav {
  svg > * {
    stroke: #ffffff;
  }
  .slick-dots {
    button {
      &:before {
        color: #fff !important;
        opacity: 1;
      }
    }
  }
}
